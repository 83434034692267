import { render, staticRenderFns } from "./BaseInputGroupTextareaUpload.vue?vue&type=template&id=39f6f662&"
import script from "./BaseInputGroupTextareaUpload.vue?vue&type=script&lang=js&"
export * from "./BaseInputGroupTextareaUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports