var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{ref:"form-group",staticClass:"base-form-group-array-draggable",class:{
    'mb-0': !_vm.columnLabel
  },attrs:{"content-cols":_vm.contentCols,"content-cols-sm":_vm.contentColsSm,"content-cols-md":_vm.contentColsMd,"content-cols-lg":_vm.contentColsLg,"content-cols-xl":_vm.contentColsXl,"label":_vm.columnLabel,"label-cols":_vm.labelCols,"label-cols-sm":_vm.labelColsSm,"label-cols-md":_vm.labelColsMd,"label-cols-lg":_vm.labelColsLg,"label-cols-xl":_vm.labelColsXl,"state":_vm.inputState},scopedSlots:_vm._u([(_vm.inputText)?{key:"description",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputText)}})]},proxy:true}:null,(_vm.inputInvalidFeedback)?{key:"invalid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputInvalidFeedback)}})]},proxy:true}:null,(_vm.inputValidFeedback)?{key:"valid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputValidFeedback)}})]},proxy:true}:null],null,true)},[_c('b-input-group',{class:{
      'has-valid': _vm.inputState === true,
      'has-invalid': _vm.inputState === false,
      'is-striped': _vm.isStriped
    },attrs:{"data-num":_vm.inputLength}},[(!_vm.inputLength)?_c('b-button',{attrs:{"variant":(_vm.inputState === false) ? 'outline-danger' : 'outline-secondary',"disabled":_vm.isLocked},on:{"click":function($event){return _vm.itemAdd()}}},[_vm._v(_vm._s(_vm.buttonLabel || _vm.$t('Add')))]):_c('draggable',_vm._g({ref:"draggableRef",staticClass:"base-form-group-array-draggable-items w-100 mx-3",attrs:{"handle":".draggable-handle","ghost-class":"draggable-copy"}},_vm.draggableListeners),_vm._l((_vm.inputValue),function(item,index){return _c('b-row',{key:_vm.draggableKeys[index],staticClass:"base-form-group-array-draggable-item align-items-center",class:{
          'is-firstchild': index === 0,
          'is-lastchild': index === _vm.inputValue.length - 1
        }},[_c('b-col',{staticClass:"text-center p-3",class:{
          'draggable-on': _vm.isSortable,
          'draggable-off': !_vm.isSortable
        }},[(_vm.isSortable)?_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.d300",modifiers:{"hover":true,"left":true,"d300":true}}],staticClass:"draggable-handle",attrs:{"name":"th","scale":"1.5","title":_vm.$t('Click and drag to re-order')}}):_vm._e(),_c('span',{staticClass:"draggable-index col-form-label"},[_c('b-badge',{staticClass:"py-1 px-2",attrs:{"pill":"","variant":"light"}},[_vm._v(_vm._s(index + 1))])],1)],1),_c('b-col',{staticClass:"py-1",attrs:{"cols":"10"}},[_c(_vm.childComponent,_vm._b({ref:_vm.draggableKeys[index],refInFor:true,tag:"component",attrs:{"namespace":(_vm.namespace + "." + index)}},'component',_vm.draggableProps,false))],1),_c('b-col',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.d300",modifiers:{"hover":true,"left":true,"d300":true}}],class:{
              'text-black-50': _vm.isLocked,
              'text-primary': !_vm.isLocked && _vm.actionKey,
              'text-secondary': !_vm.isLocked && !_vm.actionKey
            },attrs:{"disabled":_vm.isLocked,"title":_vm.actionKey ? _vm.$t('Delete All') : _vm.$t('Delete Row')},on:{"click":function($event){return _vm.itemDelete(index)}}},[_c('icon',{staticClass:"cursor-pointer mx-1",attrs:{"name":"minus-circle"}})],1),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.d300",modifiers:{"hover":true,"left":true,"d300":true}}],class:{
              'text-black-50': _vm.isLocked,
              'text-primary': !_vm.isLocked && _vm.actionKey,
              'text-secondary': !_vm.isLocked && !_vm.actionKey
            },attrs:{"disabled":_vm.isLocked,"title":_vm.actionKey ? _vm.$t('Clone Row') : _vm.$t('Add Row')},on:{"click":function($event){return _vm.itemAdd(index + 1)}}},[_c('icon',{staticClass:"cursor-pointer mx-1",attrs:{"name":"plus-circle"}})],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }