var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table-simple',_vm._b({staticClass:"base-table-sortable b-table",class:{
    'b-table-selectable': _vm.selectable
  },attrs:{"aria-multiselectable":"true","aria-busy":_vm.busy}},'b-table-simple',_vm.$attrs,false),[_c('b-thead',[_c('b-th',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.d300",modifiers:{"hover":true,"top":true,"d300":true}}],staticClass:"text-center",staticStyle:{"width":"40px"},attrs:{"aria-colindex":"1","title":_vm.$t('Drag and drop items to reorder.')}},[_c('icon',{attrs:{"name":"sort"}})],1),_vm._l((_vm.fields),function(field,fIndex){return _c('b-th',{key:field.key,class:field.class,style:(field.thStyle),attrs:{"aria-colindex":fIndex + 2}},[_vm._t(("head(" + (field.key) + ")"),function(){return [_vm._v(_vm._s(_vm.$t(field.label)))]},null,{ label: field.label, column: field.key, field: field, isFoot: false })],2)})],2),_c('draggable',{class:( _obj = {}, _obj["cursor-grabbing"] = _vm.dragging === true, _obj ),attrs:{"tag":"tbody","handle":".drag-handle","move":_vm.dragMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false},"change":_vm.dragChanged},model:{value:(_vm.dragItems),callback:function ($$v) {_vm.dragItems=$$v},expression:"dragItems"}},[(_vm.items.length === 0)?_c('b-tr',{staticClass:"b-table-empty-row"},[_c('b-td',{attrs:{"aria-colindex":"1","colspan":_vm.fields.length + 1}},[_c('div',{attrs:{"role":"alert","aria-live":"polite"}},[_vm._t("empty",null,null,{ isLoading: _vm.busy })],2)])],1):_vm._e(),_vm._l((_vm.items),function(item,iIndex){return _c('b-tr',{key:("row-" + iIndex),class:{
        'b-table-row-selected': _vm.rowsSelected[iIndex],
        'table-active': _vm.rowsSelected[iIndex]
      },attrs:{"tabindex":"0","aria-selected":(_vm.rowsSelected[iIndex]) ? 'true' : 'false'},on:{"click":function($event){return _vm.onRowClicked(iIndex)}}},[_c('b-td',{staticClass:"p-0 text-center",class:{
          'drag-handle': !item.not_sortable
        },attrs:{"aria-colindex":"1"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(item.not_sortable || _vm.items.length <= 1)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.d300",modifiers:{"hover":true,"top":true,"d300":true}}],attrs:{"title":_vm.$t('This item can not be reordered.')}},[_c('icon',{attrs:{"name":"lock"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.d300",modifiers:{"hover":true,"top":true,"d300":true}}],attrs:{"title":_vm.$t('Drag and drop item to reorder.')}},[_c('icon',{attrs:{"name":"th"}})],1)]),_vm._l((_vm.fields),function(field,fIndex){return _c('b-td',{key:((field.key) + "-" + iIndex),class:field.tdClass || field.class,attrs:{"aria-colindex":fIndex + 2}},[_vm._t(("cell(" + (field.key) + ")"),function(){return [_vm._v(_vm._s((field.formatter) ? field.formatter(item[field.key]) : item[field.key]))]},null,{
          item: item, index: iIndex, field: field,
          unformatted: item[field.key],
          value: (field.formatter) ? field.formatter(item[field.key]) : item[field.key],
          rowSelected: _vm.rowsSelected[iIndex]
        })],2)})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }