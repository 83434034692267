var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{ref:"form-group",staticClass:"base-form-group",class:{
    'mb-0': !_vm.columnLabel
  },attrs:{"content-cols":_vm.contentCols,"content-cols-sm":_vm.contentColsSm,"content-cols-md":_vm.contentColsMd,"content-cols-lg":_vm.contentColsLg,"content-cols-xl":_vm.contentColsXl,"label":_vm.columnLabel,"label-cols":_vm.labelCols,"label-cols-sm":_vm.labelColsSm,"label-cols-md":_vm.labelColsMd,"label-cols-lg":_vm.labelColsLg,"label-cols-xl":_vm.labelColsXl,"state":_vm.inputState},scopedSlots:_vm._u([(_vm.inputText || _vm.inputApiFeedback)?{key:"description",fn:function(){return [(_vm.inputApiFeedback)?_c('div',{staticClass:"text-warning",domProps:{"innerHTML":_vm._s(_vm.inputApiFeedback)}}):_vm._e(),(_vm.inputText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputText)}}):_vm._e()]},proxy:true}:null,(_vm.inputInvalidFeedback)?{key:"invalid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputInvalidFeedback)}})]},proxy:true}:null,(_vm.inputValidFeedback)?{key:"valid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputValidFeedback)}})]},proxy:true}:null],null,true)},[_c('b-input-group',{staticClass:"is-borders",class:{
      'is-focus': _vm.isFocus,
      'is-blur': !_vm.isFocus,
      'is-valid': _vm.inputState === true,
      'is-invalid': _vm.inputState === false
    },scopedSlots:_vm._u([(_vm.$slots.prepend)?{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true}:null,(_vm.$slots.append || _vm.isLocked)?{key:"append",fn:function(){return [(!_vm.isLocked)?[_vm._t("append")]:_c('b-button',{staticClass:"input-group-text",attrs:{"disabled":true,"tabIndex":"-1"}},[_c('icon',{ref:"icon-lock",attrs:{"name":"lock"}})],1)]},proxy:true}:null],null,true)},[_c('b-form-textarea',{ref:"input",staticClass:"base-form-input",attrs:{"disabled":_vm.isLocked,"readonly":_vm.inputReadonly,"state":_vm.inputState,"placeholder":_vm.inputPlaceholder,"tabIndex":_vm.inputTabIndex,"type":_vm.inputType,"value":_vm.inputValue,"rows":_vm.rows,"maxRows":_vm.maxRows},on:{"input":_vm.onInput,"change":_vm.onChange,"focus":_vm.onFocus,"blur":_vm.onBlur}})],1),(!_vm.isLocked)?[_c('b-button',{staticClass:"my-1 col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1",attrs:{"disabled":!_vm.canTest,"tabindex":"-1","variant":"outline-primary","size":"sm"},on:{"click":_vm.doTest}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isTesting),expression:"!isTesting"}]},[_vm._v(_vm._s(_vm.buttonLabel || _vm.$t('Test')))]),_c('icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTesting),expression:"isTesting"}],attrs:{"name":"circle-notch","spin":""}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }