var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.labelLeft !== false)?_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.label),expression:"label"}],staticClass:"base-input-range-label col-form-label text-nowrap mr-2",class:{
      'is-disabled': _vm.isLocked,
      'text-black-50': _vm.isLocked
    },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDecrement.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"base-input-range",class:{
      'is-focus': _vm.isFocus,
      'is-blur': !_vm.isFocus,
      'is-disabled': _vm.isLocked,
      'size-sm': _vm.size === 'sm',
      'size-md': _vm.size === 'md',
      'size-lg': _vm.size === 'lg'
    },style:(_vm.rootStyle),attrs:{"index":_vm.inputValue},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticStyle:{"pointer-events":"none"}},[_vm._l((_vm.hintStyles),function(hintStyle,index){return _c('div',{key:index,staticClass:"hint",style:(hintStyle)})}),_c('span',{staticClass:"handle",style:(_vm.valueStyle)},[(_vm.isLocked)?_c('icon',{attrs:{"name":"lock"}}):(_vm.icon)?_c('icon',{attrs:{"name":_vm.icon}}):_vm._t("default")],2),(_vm.tooltip)?_c('div',{staticClass:"tooltip",style:(_vm.valueStyle)},[_c('span',{attrs:{"id":"value"}},[_vm._v(_vm._s(_vm.tooltip))])]):_vm._e()],2),_c('input',{ref:"input",attrs:{"type":"range","tabIndex":_vm.tabIndex,"max":_vm.max,"min":_vm.min,"step":_vm.step,"disabled":_vm.isLocked},domProps:{"value":_vm.inputValue || _vm.defaultValue},on:{"change":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}})]),(_vm.labelRight !== false)?_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.label),expression:"label"}],staticClass:"base-input-range-label text-nowrap ml-2",class:{
      'is-disabled': _vm.isLocked,
      'text-black-50': _vm.isLocked
    },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onIncrement.apply(null, arguments)}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }