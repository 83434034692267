import { render, staticRenderFns } from "./BaseButtonConfirm.vue?vue&type=template&id=fe464d62&"
import script from "./BaseButtonConfirm.vue?vue&type=script&lang=js&"
export * from "./BaseButtonConfirm.vue?vue&type=script&lang=js&"
import style0 from "./BaseButtonConfirm.vue?vue&type=style&index=0&id=fe464d62&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports