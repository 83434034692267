var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{ref:"form-group",staticClass:"base-form-group",class:{
    'mb-0': !_vm.columnLabel
  },attrs:{"content-cols":_vm.contentCols,"content-cols-sm":_vm.contentColsSm,"content-cols-md":_vm.contentColsMd,"content-cols-lg":_vm.contentColsLg,"content-cols-xl":_vm.contentColsXl,"label":_vm.columnLabel,"label-cols":_vm.labelCols,"label-cols-sm":_vm.labelColsSm,"label-cols-md":_vm.labelColsMd,"label-cols-lg":_vm.labelColsLg,"label-cols-xl":_vm.labelColsXl,"state":_vm.inputState},scopedSlots:_vm._u([(_vm.inputText || _vm.inputApiFeedback)?{key:"description",fn:function(){return [(_vm.inputApiFeedback)?_c('div',{staticClass:"text-warning",domProps:{"innerHTML":_vm._s(_vm.inputApiFeedback)}}):_vm._e(),(_vm.inputText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputText)}}):_vm._e()]},proxy:true}:null,(_vm.inputInvalidFeedback)?{key:"invalid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputInvalidFeedback)}})]},proxy:true}:null,(_vm.inputValidFeedback)?{key:"valid-feedback",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.inputValidFeedback)}})]},proxy:true}:null],null,true)},[_c('b-input-group',{staticClass:"base-input-group",class:{
      'is-focus': _vm.isFocus,
      'is-blur': !_vm.isFocus,
      'is-valid': _vm.inputState === true,
      'is-invalid': _vm.inputState === false
    },scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.prefixesInRange.length > 0)?_c('b-dropdown',{staticClass:"base-input-dropdown",attrs:{"size":"sm","variant":"light"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.prefix.label + _vm.units.label))])]},proxy:true}],null,false,4112578994)},[_vm._l((_vm.prefixesInRange),function(p,i){return [_c('b-dropdown-item-button',{key:i,attrs:{"active":p.label === _vm.prefix.label},on:{"click":function($event){return _vm.onChangePrefix(p)}}},[_vm._v(_vm._s(p.label + _vm.units.label)+" - "+_vm._s(p.name + _vm.units.name))])]})],2):_vm._e(),(_vm.isLocked)?_c('b-button',{staticClass:"input-group-text",attrs:{"disabled":true,"tabIndex":"-1"}},[_c('icon',{ref:"icon-lock",attrs:{"name":"lock"}})],1):_vm._e()]},proxy:true}])},[_c('b-form-input',{ref:"input",staticClass:"base-form-group-input",attrs:{"data-namespace":_vm.namespace,"disabled":_vm.isLocked,"readonly":_vm.inputReadonly,"state":_vm.inputState,"placeholder":_vm.inputPlaceholder,"tabIndex":_vm.inputTabIndex,"type":_vm.inputType,"value":_vm.inputValue},on:{"input":_vm.onInput,"change":_vm.onChange,"focus":_vm.onFocus,"blur":_vm.onBlur}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }