var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.inputLength)?_c('b-button',{attrs:{"variant":(_vm.inputState === false) ? 'outline-danger' : 'outline-secondary',"disabled":_vm.isLocked},on:{"click":function($event){return _vm.itemAdd()}}},[_vm._v(_vm._s(_vm.buttonLabel || _vm.$t('Add')))]):_c('div',{staticClass:"base-input-array-items mx-3"},_vm._l((_vm.inputValue),function(item,index){return _c('b-row',{key:_vm.draggableKeys[index],staticClass:"base-input-array-item align-items-center",class:{
        'is-firstchild': index === 0,
        'is-lastchild': index === _vm.inputValue.length - 1
      }},[_c('b-col',{staticClass:"text-center p-3"},[_c('span',{staticClass:"col-form-label "},[_c('b-badge',{staticClass:"py-1 px-2",attrs:{"pill":"","variant":"light"}},[_vm._v(_vm._s(index + 1))])],1)]),_c('b-col',{attrs:{"cols":"10"}},[_c(_vm.childComponent,{ref:_vm.draggableKeys[index],refInFor:true,tag:"component",attrs:{"namespace":(_vm.namespace + "." + index)}})],1),_c('b-col',[_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.d300",modifiers:{"hover":true,"left":true,"d300":true}}],class:{
            'text-black-50': _vm.isLocked,
            'text-primary': !_vm.isLocked && _vm.actionKey,
            'text-secondary': !_vm.isLocked && !_vm.actionKey
          },attrs:{"disabled":_vm.isLocked,"title":_vm.actionKey ? _vm.$t('Delete All') : _vm.$t('Delete Row'),"tabIndex":"-1"},on:{"click":function($event){return _vm.itemDelete(index)}}},[_c('icon',{staticClass:"cursor-pointer mx-1",attrs:{"name":"minus-circle"}})],1),_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left.d300",modifiers:{"hover":true,"left":true,"d300":true}}],class:{
            'text-black-50': _vm.isLocked,
            'text-primary': !_vm.isLocked && _vm.actionKey,
            'text-secondary': !_vm.isLocked && !_vm.actionKey
          },attrs:{"disabled":_vm.isLocked,"title":_vm.actionKey ? _vm.$t('Clone Row') : _vm.$t('Add Row'),"tabIndex":"-1"},on:{"click":function($event){return _vm.itemAdd(index + 1)}}},[_c('icon',{staticClass:"cursor-pointer mx-1",attrs:{"name":"plus-circle"}})],1)],1)],1)}),1),(_vm.inputText)?_c('small',{domProps:{"innerHTML":_vm._s(_vm.inputText)}}):_vm._e(),(_vm.inputInvalidFeedback)?_c('small',{staticClass:"invalid-feedback",domProps:{"innerHTML":_vm._s(_vm.inputInvalidFeedback)}}):_vm._e(),(_vm.inputValidFeedback)?_c('small',{staticClass:"valid-feedback",domProps:{"innerHTML":_vm._s(_vm.inputValidFeedback)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }